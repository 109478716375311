import React, { FC, FunctionComponent, ReactElement, SVGAttributes, useState } from 'react';
import styled, { css } from 'styled-components';
import Image from 'next/legacy/image';
import { motion, Variants } from 'framer-motion';
import Camper from '../../../../public/static/images/promo-blocks/dodej-campera-min.png';
import FirsTime from '../../../../public/static/images/promo-blocks/first-time-min.png';
import RoadTrip from '../../../../public/static/images/promo-blocks/roadtrip.png';
import Voucher from '../../../../public/static/compressedImages/landing/voucherChristmas/pageHeaderBg.png';
import Voucher2 from '../../../../public/static/compressedImages/landing/voucherChristmas/chirstmasVoucher2.png';
import Voucher3 from '../../../../public/static/images/landing/voucherChristmas/Vouchery_Webpodklad_thumb.jpg';
import EgoeSnow from '../../../../public/static/compressedImages/promo-blocks/egoe.jpg';
import Spain from '../../../../public/static/images/promo-blocks/spain-min.png';
import SpainNew from '../../../../public/static/images/promo-blocks/spain_new.png';
import HomepageSpain from '../../../../public/static/images/promo-blocks/content-block_spain.png';
import Sun from '../../../../public/static/images/promo-blocks/sun.svg';
import RoadPoint from '../../../../public/static/images/promo-blocks/road-point.svg';
import Present from '../../../../public/static/images/promo-blocks/present.svg';
import Zloty from '../../../../public/static/images/promo-blocks/zloty.svg';
import { useTypedSelector } from '~/src/redux/store';
import { settingsSelector } from '~/src/redux/selectors/settings';
import Snow from '../../../../public/static/images/promo-blocks/snow.svg';
import RvSchoolIcon from '../../../../public/static/compressedImages/promo-blocks/rvSchoolIcon.png';
import RvSchoolBackground from '../../../../public/static/compressedImages/promo-blocks/rvSchool.png';
import SixPlus1 from '../../../../public/static/images/promo-blocks/leto-6-plus-1.png';
import ThreePlus1 from '../../../../public/static/images/promo-blocks/3plus1.png';

import { deviceMaxWidth, deviceMinWidth } from '~/src/styles/breakpoints';
import LocalizedLink from '~/src/components/LocalizedLink';
import { RouteType } from '~/src/utils/routing';
import { trackHomepageBannerClick, tryCallDataLayerEvent } from '~/src/utils/dataLayer';

import MarketingAwareness from '../../../../public/static/images/promo-blocks/marketing-awarness.svg';
import MarketingAwarenessBackground from '../../../../public/static/compressedImages/promo-blocks/marketingAwarenessPromo.jpg';
import { StaticImageData } from 'next/image';

type ImageSource =
  | 'camper'
  | 'first-time'
  | 'spain'
  | 'spain-new'
  | 'homepage-spain'
  | 'voucher'
  | 'egore_snow'
  | 'marketing_awareness'
  | 'rv-school'
  | 'roadtrip'
  | 'christmas-voucher'
  | '6plus1'
  | '3plus1'
  | 'christmas-voucher-2';
type IconSource = 'sun' | 'road-point' | 'zloty' | 'present' | 'snow' | 'marketing_awareness' | 'rv-school';

const determineImageSource = (imageSource: ImageSource) => {
  switch (imageSource) {
    case 'camper':
      return Camper;
    case 'first-time':
      return FirsTime;
    case 'roadtrip':
      return RoadTrip;
    case 'spain':
      return Spain;
    case 'spain-new':
      return SpainNew;
    case 'homepage-spain':
      return HomepageSpain;
    case 'egore_snow':
      return EgoeSnow;
    case 'voucher':
      return Voucher;
    case 'marketing_awareness':
      return MarketingAwarenessBackground;
    case 'rv-school':
      return RvSchoolBackground;
    case 'christmas-voucher':
      return Voucher2;
    case 'christmas-voucher-2':
      return Voucher3;
    case '6plus1':
      return SixPlus1;
    case '3plus1':
      return ThreePlus1;
    default:
      return Spain;
  }
};

const determineIconSource = (
  iconSource: IconSource
): { Icon: FunctionComponent<SVGAttributes<SVGElement>> } | { image: StaticImageData } => {
  switch (iconSource) {
    case 'sun':
      return { Icon: Sun };
    case 'road-point':
      return { Icon: RoadPoint };
    case 'present':
      return { Icon: Present };
    case 'zloty':
      return { Icon: Zloty };
    case 'snow':
      return { Icon: Snow };
    case 'marketing_awareness':
      return { Icon: MarketingAwareness };
    case 'rv-school':
      return { image: RvSchoolIcon };
    default:
      return { image: Spain };
  }
};

const LearnMoreVariants: Variants = {
  hidden: { opacity: 0, x: -10 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.2,
      duration: 0.5,
      type: 'tween',
      ease: 'easeOut',
    },
  },
};
const CategoryVariants: Variants = {
  initial: { color: '#DAD1D1' },
  animate: (hovered) => ({
    color: hovered ? '#EDEAE3' : '#DAD1D1',
  }),
};

interface PromoItemCardProps {
  text: string | ReactElement;
  category?: string | ReactElement;
  imageSource: ImageSource;
  linksTo?: RouteType;
  iconSource: IconSource;
  CTAText: string;
  externalLink?: string;
  linkParams?: any;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => unknown;
}

export const PromoItemCard: FC<PromoItemCardProps> = ({
  text,
  category,
  imageSource,
  iconSource,
  linksTo,
  CTAText,
  externalLink,
  linkParams = {},
  onClick,
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const settings = useTypedSelector(settingsSelector);

  const iconObj = determineIconSource(iconSource);

  if (externalLink) {
    return (
      <a href={externalLink} onClick={onClick}>
        <CardWrapper
          onMouseOver={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          className="initialLessOpacitys"
        >
          <IconContainer className={['sun', 'zloty', 'rv-school'].includes(iconSource) ? 'largeIcon' : 'normalIcon'}>
            {'image' in iconObj ? (
              <Image
                src={iconObj.image}
                layout="fixed"
                width={['sun', 'zloty', 'rv-school'].includes(iconSource) ? 60 : 34}
                height={40}
              />
            ) : (
              <iconObj.Icon width={['sun', 'zloty', 'rv-school'].includes(iconSource) ? 60 : 34} />
            )}
          </IconContainer>
          <ImageWrapper
            className="imgWrapper"
            darken={[
              'egore_snow',
              'marketing_awareness',
              'rv-school',
              'spain-new',
              'christmas-voucher-2',
              '6plus1',
              '3plus1',
            ].includes(imageSource)}
          >
            <Image
              src={determineImageSource(imageSource)}
              layout="fill"
              objectFit="cover"
              placeholder="blur"
              quality={90}
            />
          </ImageWrapper>
          <TextContainer>
            {category && (
              <StyledCategory
                animate="animate"
                initial={true}
                custom={isMouseOver}
                variants={CategoryVariants}
                layout={true}
              >
                {category}
              </StyledCategory>
            )}
            <ContentText layout={true}>{text}</ContentText>

            {isMouseOver && (
              <LearnMoreText initial={false} animate="visible" variants={LearnMoreVariants}>
                {CTAText} <i className="icon-action-forward" />
              </LearnMoreText>
            )}
          </TextContainer>
        </CardWrapper>
      </a>
    );
  }

  return (
    <LocalizedLink
      linkParams={linkParams}
      href={linksTo}
      passHref={true}
      onClick={onClick}
      forceLegacyFlag={true}
      legacyBehavior={false}
    >
      <CardWrapper
        onClick={(e) => {
          console.log(e);
          tryCallDataLayerEvent(() => {
            trackHomepageBannerClick(text, linksTo?.urls[settings?.language]);
          });
        }}
        onMouseOver={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <IconContainer className={['sun', 'zloty'].includes(iconSource) ? 'largeIcon' : 'normalIcon'}>
          {'image' in iconObj ? (
            <Image
              src={iconObj.image}
              layout="fixed"
              width={['sun', 'zloty'].includes(iconSource) ? 60 : 34}
              height={40}
            />
          ) : (
            <iconObj.Icon width={['sun', 'zloty'].includes(iconSource) ? 60 : 34} />
          )}
        </IconContainer>
        <ImageWrapper
          className="imgWrapper"
          darken={[
            'christmas-voucher',
            'egore_snow',
            'homepage-spain',
            'roadtrip',
            'christmas-voucher-2',
            '6plus1',
            '3plus1',
          ].includes(imageSource)}
        >
          <Image
            src={determineImageSource(imageSource)}
            layout="fill"
            objectFit="cover"
            placeholder="blur"
            quality={90}
          />
        </ImageWrapper>
        <TextContainer>
          <StyledCategory
            animate="animate"
            initial={true}
            custom={isMouseOver}
            variants={CategoryVariants}
            layout={true}
          >
            {category}
          </StyledCategory>
          <ContentText layout={true}>{text}</ContentText>

          {isMouseOver && (
            <LearnMoreText initial={false} animate="visible" variants={LearnMoreVariants}>
              {CTAText} <i className="icon-action-forward" />
            </LearnMoreText>
          )}
        </TextContainer>
      </CardWrapper>
    </LocalizedLink>
  );
};

const CardWrapper = styled.div`
  position: relative;
  user-select: none;
  //fix ios bug with not rounded borders
  transform: translateZ(0);
  display: flex;
  flex-direction: column;
  width: 240px;
  min-width: 240px;
  min-height: 132px;
  height: 132px;
  border-radius: 8px;
  overflow: hidden;
  justify-content: space-between;

  @media ${deviceMinWidth.phone} {
    width: 325px;
    min-width: 325px;
    min-height: 180px;
    height: 180px;
  }

  :hover .imgWrapper::before {
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }
`;

const ImageWrapper = styled.div<{ darken?: boolean }>`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(31, 34, 68, 0.25);
    opacity: 0;
    z-index: 1;
    transition: opacity 0.5s ease-in;
  }

  ${({ darken }) =>
    darken &&
    css`
      img {
        filter: brightness(55%);
      }
    `}
`;

const TextContainer = styled(motion.div)`
  position: relative;
  z-index: 2;
  padding: 12px;
  cursor: pointer;

  @media ${deviceMinWidth.phone} {
    padding: 0 16px 16px 16px;
  }
`;

const StyledCategory = styled(motion.h6)`
  color: #dad1d1;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  user-select: none;
  text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.32);
  margin-bottom: 8px;
`;

const ContentText = styled(motion.p)`
  user-select: none;
  font-family: var(--font-baloo);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #edeae3;
  text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.32);
  @media (min-width: 430px) {
    font-size: 20px;
  }

  @media ${deviceMinWidth.phone} {
    font-size: 24px;
    line-height: 24px;
  }
`;

const IconContainer = styled.div`
  padding: 16px 0 0 16px;
  @media ${deviceMaxWidth.phone} {
    padding: 12px 0 0 12px;

    &.largeIcon > span {
      width: 40px !important;
      height: 28px !important;
    }

    &.normalIcon > span {
      width: 24px !important;
      height: 28px !important;
    }
  }
`;

const LearnMoreText = styled(motion.p)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.32);
  margin-top: 8px;

  i {
    font-weight: 900;
  }

  @media ${deviceMaxWidth.phone} {
    display: none;
  }
`;
